<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.user')">
      <b-form @submit.prevent="search">
        <div class="row">
          <div class="col-sm-6 col-md-2">
            {{ $t("message.userName") }} :
            <br />
            <b-form-input v-model="searchData.userName" />
          </div>
          <div class="col-sm-6 col-md-2">
            {{ $t("message.role") }} :
            <br />
            <b-form-select v-model="searchData.role" :options="role_select" />
          </div>
          <div class="col-sm-6 col-md-1">
            <br />
            <b-button variant="warning" :active="true" type="submit">
              <i class="fa fa-search"></i>&nbsp;ค้นหา
            </b-button>
          </div>
        </div>
      </b-form>
      <br />
      <div class="table-responsive">
        <table class="table-striped table2">
          <thead class="bg-primary" style="text-align: center">
            <th>USER ID</th>
            <th>{{ $t("message.userName") }}</th>
            <th>{{ $t("message.role") }}</th>
            <th>credit</th>
            <th>sms credit</th>
            <th>{{ $t("message.createdAt") }}</th>
            <th>{{ $t("message.updatedAt") }}</th>
            <th>Action</th>
          </thead>
          <tbody>
            <template v-for="items in rowData">
              <tr style="text-align: center">
                <td>{{ items.userId }}</td>
                <td>{{ items.userName }}</td>
                <td>{{ items.role }}</td>
                <td style="text-align: right">
                  {{ items.credit ? items.credit.value : "" }}
                </td>
                <td style="text-align: right">
                  {{ items.credit ? items.credit.valueSMS : "" }}
                </td>
                <!--             <template v-if="items.status">
              <td><c-switch type="text" variant="success" on="On" off="Off" :pill="true" :checked="true" size="lg"/></td>   
            </template>
            <template v-else>
              <td><c-switch type="text" variant="success" on="On" off="Off" :pill="true" :checked="false" size="lg"/></td> 
                </template>-->
                <td>{{ items.createdAt | moment("YYYY-MM-DD HH:mm") }}</td>
                <td>{{ items.updatedAt | moment("YYYY-MM-DD HH:mm") }}</td>

                <td>
                  <b-btn
                    size="sm"
                    variant="success"
                    @click="showModalUserInfo(items)"
                    >{{ $t("message.edit") }}</b-btn
                  >&nbsp;
                  <b-btn
                    size="sm"
                    variant="primary"
                    @click="showModalPwd(items)"
                    >{{ $t("message.changePassword") }}</b-btn
                  >&nbsp;
                  <b-btn
                    size="sm"
                    variant="danger"
                    @click="showModalAddcredit(items)"
                    >{{ $t("message.addCredit") }}</b-btn
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <br />
      <b-pagination
        size="md"
        :total-rows="totalRows"
        v-model="pagination"
        :per-page="itemperPage"
        @input="pageChanged(pagination)"
      ></b-pagination>
    </b-card>

    <b-modal
      ref="changePwd"
      :title="$t('message.changePassword')"
      @ok="changePwd"
    >
      <table class="table3">
        <tr>
          <td>{{ $t("message.oldPassword") }}</td>
          <td>
            <b-form-input
              class="form-control"
              type="password"
              v-model="ModalNewPwd.oldPassword"
              required
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.newPassword") }}</td>
          <td>
            <b-form-input
              class="form-control"
              type="password"
              v-model="newPassWord"
              required
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.confirmPassword") }}</td>
          <td>
            <b-form-input
              class="form-control"
              type="password"
              v-model="confirmPassWord"
              required
            ></b-form-input>
          </td>
        </tr>
      </table>
    </b-modal>

    <b-modal ref="userInfoModal" @ok="editUserInfo" size="lg">
      <table class="table2">
        <!-- <tr>
          <td>{{ $t("message.userName") }}</td>
          <td colspan="2">
            <b-form-input v-model="detail.userName" />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>{{ $t("message.password") }}</td>
          <td colspan="2">
            <b-form-input v-model="detail.passWord" type="password" />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>{{ $t("message.role") }}</td>
          <td colspan="2">
            <b-form-select v-model="detail.role" :options="role_select" />
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.type") }}</td>
          <td colspan="2">
            <b-form-select v-model="detail.type" :options="userType" />
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.titleName") }}</td>
          <td colspan="2">
            <b-form-select v-model="detail.titlename" :options="titlename" />
          </td>
        </tr>-->
        <tr>
          <td>{{ $t("message.userName") }}</td>
          <td>
            <b-form-input placeholder="username" v-model="detail.userName" />
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.nameTH") }}</td>
          <td>
            <b-form-input placeholder="ชื่อ" v-model="detail.firstNameTh" />
          </td>
          <td>
            <b-form-input placeholder="นามสกุล" v-model="detail.lastNameTh" />
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.nameEN") }}</td>
          <td>
            <b-form-input
              placeholder="First Name"
              v-model="detail.firstNameEn"
            />
          </td>
          <td>
            <b-form-input placeholder="Last Name" v-model="detail.lastNameEn" />
          </td>
        </tr>

        <!-- <tr>
          <td>{{ $t("message.idCard") }}</td>
          <td colspan="2">
            <b-form-input v-model="detail.idCard" />
          </td>
          <td></td>
        </tr>-->
        <tr>
          <td>{{ $t("message.refNumber") }}</td>
          <td colspan="2">
            <b-form-input v-model="detail.phoneNumber" />
          </td>
          <td></td>
        </tr>
        <tr></tr>
        <tr>
          <td>{{ $t("message.smsNumber") }} 1</td>
          <td colspan="2">
            <b-form-input v-model="detail.smsNumber1" />
          </td>
          <td></td>
        </tr>
        <tr></tr>
        <tr>
          <td>{{ $t("message.smsNumber") }} 2</td>
          <td colspan="2">
            <b-form-input v-model="detail.smsNumber2" />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>{{ $t("message.email") }}</td>
          <td colspan="2">
            <b-form-input v-model="detail.email" />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>{{ $t("message.address") }}</td>
          <td>
            <b-form-input
              :placeholder="$t('message.addressName')"
              v-model="address.name"
            />
          </td>
          <td>
            <b-form-input
              :placeholder="$t('message.roomNum')"
              v-model="address.roomNum"
            />
          </td>
        </tr>
        <tr>
          <td>
            <b-form-input
              :placeholder="$t('message.floor')"
              v-model="address.floor"
            />
          </td>
          <td>
            <b-form-input
              :placeholder="$t('message.addressNum')"
              v-model="address.number"
            />
          </td>
          <td>
            <b-form-input
              :placeholder="$t('message.villeageNum')"
              v-model="address.villeageNum"
            />
          </td>
        </tr>
        <tr>
          <td>
            <b-form-input
              :placeholder="$t('message.alley')"
              v-model="address.alley"
            />
          </td>
          <td>
            <b-form-input
              :placeholder="$t('message.road')"
              v-model="address.road"
            />
          </td>
          <td>
            <b-form-input
              :placeholder="$t('message.province')"
              v-model="address.province"
            />
          </td>
        </tr>
        <tr>
          <td>
            <b-form-input
              :placeholder="$t('message.district')"
              v-model="address.district"
            />
          </td>
          <td>
            <b-form-input
              :placeholder="$t('message.subDistrict')"
              v-model="address.sub_district"
            />
          </td>
          <td>
            <b-form-input
              :placeholder="$t('message.postcode')"
              v-model="address.postcode"
            />
          </td>
        </tr>
      </table>
    </b-modal>

    <b-modal
      ref="addCreditModal"
      :title="$t('message.addCredit')"
      @ok="addCredit"
    >
      <table class="table3">
        <tr>
          <td>{{ $t("message.userName") }}</td>
          <td>
            {{ modalAddcredit.userName }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.type") }}</td>
          <td>
            <b-form-select
              v-model="addCreditType"
              :options="addCreditSelector"
            />
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.addOrDeductCredit") }}</td>
          <td>
            <b-form-select
              v-model="modalAddcredit.creditType"
              :options="creditType"
            />
          </td>
        </tr>
        <tr v-if="addCreditType === 'credit'">
          <td>{{ $t("message.value") }}</td>
          <td>
            <b-form-input
              class="form-control"
              type="number"
              v-model.number="modalAddcredit.value"
              min="0"
              required
            ></b-form-input>
          </td>
        </tr>
        <tr v-if="addCreditType === 'sms'">
          <td>{{ $t("message.value") }}</td>
          <td>
            <b-form-input
              class="form-control"
              type="number"
              min="0"
              v-model.number="modalAddcredit.valueSMS"
              required
            ></b-form-input>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import cSwitch from "../../components/Switch";
import webServices from "../../script";
export default {
  name: "users",
  components: {
    cSwitch,
  },
  computed: {
    CheckPwd() {
      return "false";
    },
  },
  data() {
    return {
      searchData: {},
      role_select: [
        { value: null, text: "All" },
        { value: "admin", text: "admin" },
        { value: "dealer", text: "dealer" },
        { value: "user", text: "user" },
      ],
      addCreditSelector: [
        { value: "sms", text: "sms" },
        { value: "credit", text: "credit" },
      ],
      creditType: [
        { value: "add", text: "เพิ่ม" },
        { value: "deduct", text: "หัก" },
      ],
      status_select: [
        { value: null, text: "All" },
        { value: true, text: "ON" },
        { value: false, text: "OFF" },
      ],
      userType: [
        { value: null, text: "Select type" },
        { value: "admin", text: "admin" },
        { value: "wonderwash", text: "wonderwash" },
        { value: "oem", text: "oem" },
        { value: "callcenter", text: "callcenter" },
        { value: "other", text: "อื่นๆ" },
      ],
      titlename: [
        { value: null, text: "Select title" },
        { value: "mr", text: "Mr. นาย" },
        { value: "ms", text: "Miss. นางสาว" },
        { value: "mrs", text: "Mrs. นาง" },
      ],
      role_select: [
        { value: null, text: "Select type" },
        { value: "admin", text: "admin" },
        { value: "dealer", text: "dealer" },
        { value: "owner", text: "owner" },
      ],
      address: {},
      rowData: [],
      pagination: 1,
      totalRows: 1,
      itemperPage: 30,
      newPassWord: "",
      confirmPassWord: "",
      ModalNewPwd: {
        userId: 0,
        userName: "",
      },
      modalAddcredit: {
        userId: 0,
        userName: "",
        creditType: "",
      },
      detail: {},
      addCreditType: "",
    };
  },
  methods: {
    getUserList(params) {
      this.$Progress.start();
      webServices
        .getUserList(params)
        .then((res) => {
          // console.log(res.data)
          this.$Progress.finish();
          this.rowData = res.data;
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          console.log("error @user");
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    search() {
      this.getUserList(this.searchData);
    },
    editData(user) {
      // console.log(user)
    },
    showModalPwd(user) {
      this.ModalNewPwd.userId = user.userId;
      this.ModalNewPwd.userName = user.userName;
      this.$refs.changePwd.show();
    },
    showModalAddcredit(user) {
      this.modalAddcredit.userId = user.userId;
      this.modalAddcredit.userName = user.userName;
      this.$refs.addCreditModal.show();
    },
    showModalUserInfo(user) {
      webServices
        .getUserInfo({ userId: user.userId })
        .then((info) => {
          if (info.data.success) {
            this.detail = info.data.info;
            this.detail.userName = user.userName;
            this.$refs.userInfoModal.show();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log("error @change password");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
      // console.log(this.ModalNewPwd)
    },
    addCredit() {
      if (this.addCreditType === "sms") {
        if (this.modalAddcredit.creditType === "deduct") {
          this.modalAddcredit.valueSMS = this.modalAddcredit.valueSMS * -1;
        }
        webServices.addCreditSMS(this.modalAddcredit).then((res) => {
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ",
            });
            this.addCreditType = "";
            this.modalAddcredit = {
              userId: 0,
              userName: "",
            };
            this.getUserList();
          } else {
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error),
            });
          }
        });
      } else if (this.addCreditType === "credit") {
        if (this.modalAddcredit.creditType === "deduct") {
          this.modalAddcredit.value = this.modalAddcredit.value * -1;
        }
        webServices.addCredit(this.modalAddcredit).then((res) => {
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ",
            });
            this.addCreditType = "";
            this.modalAddcredit = {
              userId: 0,
              userName: "",
            };
            this.getUserList();
          } else {
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error),
            });
          }
        });
      }
    },
    editUserInfo() {
      webServices.editUserInfo(this.detail).then((res) => {
        if (res.data.success) {
          this.$toast.success({
            title: "SUCCESS",
            message: "สำเร็จ",
          });
        } else {
          const error = webServices.showError(res.data);
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(error),
          });
        }
        this.getUserList(this.searchData);
      });
    },
    changePwd() {
      if (
        this.newPassWord === this.confirmPassWord &&
        this.newPassWord &&
        this.confirmPassWord &&
        this.ModalNewPwd.oldPassword
      ) {
        this.ModalNewPwd.passWord = this.newPassWord;
        // console.log(this.ModalNewPwd)
        webServices
          .changePwd(this.ModalNewPwd)
          .then((res) => {
            // console.log(res)
            if (res.data.success) {
              this.clearPassWord();
              this.$toast.success({
                title: "SUCCESS",
                message: "เปลี่ยนรหัสผ่านสำเร็จ",
              });
            } else {
              const error = webServices.showError(res.data);
              this.$toast.error({
                title: "ERROR",
                message: JSON.stringify(error),
              });
            }
            this.ModalNewPwd = {};
            this.getUserList();
          })
          .catch((err) => {
            if (err.response.status === 401) {
              webServices.tokenExpire();
            }
            console.log("error @change password");
            console.log(err);
            this.$toast.error({
              title: "ERROR",
              message: "เกิดข้อผิดพลาด",
            });
            if (err.response.status === 401) {
              webServices.tokenExpire();
            }
          });
      } else {
        this.$toast.error({
          title: "ERROR",
          message: "กรุณากรอกข้อมูลให้ถูกต้อง",
        });
      }
    },
    clearPassWord() {
      this.newPassWord = "";
      this.confirmPassWord = "";
      this.ModalNewPwd = {};
    },
  },
  mounted() {
    this.getUserList(this.searchData);
  },
};
</script>

<style lang="scss">
.table3 {
  width: 100%;
  border: none;
}
.table3 td,
.table3 th {
  padding: 8px;
  vertical-align: middle;
  text-align: center;
}
</style>